
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.contact {
  .block__row {
    margin-bottom: 16px;
  }
}
